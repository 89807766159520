import React, { useEffect } from "react";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../../styles/main.scss";

import logo from "../../images/mamaai_logo--white.svg";
import contentContext from "../../images/products/machine-translation/content-context.jpg";
import cloudConnection from "../../images/products/machine-translation/cloud-connection.jpg";
import server from "../../images/products/machine-translation/server.jpg";

import Metadata from "../../js/Metadata";
import Navbar from "../../js/Navbar";
import Footer from "../../js/Footer";
import AiAcademyToast from "../../js/layout/ai_academy_toast";

import Swal from "sweetalert2";

const MachineTranslationPage = () => {
    const contactFormUrl =
        "https://mlh0rbexm3.execute-api.us-east-2.amazonaws.com/default/ContactUsLog?TableName=ContactUsLogs";
    //const contactFormUrl = "https://httpbin.org/post"

    const description =
        "Machine Translation is product of The MAMA AI enabling translation through artificial intelligence.";

    async function fetchUrl(url, data) {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *folslow, error
            referrer: "client", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        await response.json();
        Swal.fire({
            title: "Thank you!",
            text: "Your message has been sent!",
            icon: "success",
            confirmButtonColor: "#FAA847",
        });
    }

    useEffect(() => {
        const handleDemoSubmit = (event) => {
            event.preventDefault();

            const contactFormData2 = {
                Item: {
                    topic: {
                        S: "Request Machine Translation Information",
                    },
                    email: {
                        S: document.getElementById("sender-email2").value,
                    },
                    message: {
                        S: document.getElementById("message-text2").value,
                    },
                },
                TableName: "ContactUsLogs",
            };

            fetchUrl(contactFormUrl, contactFormData2);
        };
        document
            .getElementById("submit-btn2")
            .addEventListener("click", handleDemoSubmit);
    }, []);

    return (
        <div className="container-max-width">
            <Metadata
                title="THE MAMA AI Machine Translation"
                url="https://themama.ai/products/machine-translation"
                description={description}
            />
            <Navbar />
            <section className="ai-logo-section section g-0 pb-5">
                <div>
                    <div id="home" className="section__anchor" />
                    <header className="text-center logo-header m-auto">
                        <a href="/#home">
                            <img
                                className="logo-header__logo d-block m-auto"
                                src={logo}
                                alt="The mama AI"
                            />
                        </a>
                        <h1 className="fs-3 pt-5 text--color-2">
                            Machine Translation
                        </h1>
                    </header>
                </div>
            </section>
            <section className="machine-translation-section section pb-5 pt-5">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-4 align-self-center order-lg-2">
                                    <p>
                                        <span className="fw-bold">
                                            High-Performance Machine Learning
                                            Models
                                        </span>
                                        : Utilize state-of-the-art neural
                                        translation models for contextually
                                        relevant translations.
                                    </p>
                                    <p className="pt-3 pb-3 pb-lg-0">
                                        <span className="fw-bold">
                                            Domain Adaptation
                                        </span>
                                        : Enhance translation quality using your
                                        industry in-domain data.
                                    </p>
                                </div>
                                <div className="col-lg-5 col-xl-4 align-self-center order-lg-1 text-center text-lg-start">
                                    <img
                                        src={contentContext}
                                        alt="Content & Context"
                                        className="img-fluid rounded translation-image"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-4 align-self-center">
                                    <p>
                                        <span className="fw-bold">
                                            Easy Integration
                                        </span>
                                        : Integrate with existing applications
                                        and workflows via REST API.
                                    </p>
                                    <p className="pt-3 pb-3 pb-lg-0">
                                        <span className="fw-bold">
                                            Kubernetes-Native Implementation
                                        </span>
                                        : Experience seamless scaling and robust
                                        performance, operating natively within
                                        Kubernetes for efficient resource
                                        management.
                                    </p>
                                </div>
                                <div className="align-self-center col-lg-5 col-xl-4 text-lg-end text-center">
                                    <img
                                        src={server}
                                        alt="Server image"
                                        className="img-fluid rounded translation-image"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-4 align-self-center order-lg-2">
                                    <p>
                                        <span className="fw-bold">
                                            SaaS/On-Prem
                                        </span>
                                        : Retain control over data and
                                        translations, ensuring data security and
                                        compliance.
                                    </p>
                                    <p className="pt-3 pb-3 pb-lg-0">
                                        <span className="fw-bold">
                                            Consultations and Upskilling
                                        </span>
                                        : Our team of experts will guide you
                                        through customizing any translation
                                        services to effectively address your
                                        unique business tasks and requirements.
                                    </p>
                                </div>
                                <div className="align-self-center col-lg-5 col-xl-4 order-lg-1 text-center text-lg-start">
                                    <img
                                        src={cloudConnection}
                                        alt="Cloud Connection"
                                        className="img-fluid rounded translation-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5 section--light contact-us-section">
                <div className="container px-8">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-lg-6 align-self-center">
                            <h1 className="pt-1">
                                We can supply also voice communication
                                technologies.
                            </h1>
                            <h2 className="pt-3">
                                Actually, we have numerous excellent AI
                                products. Would you like to buy some?
                            </h2>
                        </div>
                        <div className="col-12 col-lg-4 offset-xl-1">
                            <form>
                                <h1
                                    className="modal-title"
                                    id="requestADemoLabel"
                                >
                                    Request information
                                </h1>
                                <div className="mb-3">
                                    <label
                                        htmlFor="sender-email2"
                                        className="col-form-label"
                                    >
                                        E-mail:
                                        <span className="form-asterisk">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="sender-email2"
                                        required
                                    />
                                    <label
                                        htmlFor="message-text2"
                                        className="col-form-label"
                                    >
                                        Message: (optional)
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="message-text2"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary text-white main-navbar__demo-button modal-button"
                                    id="submit-btn2"
                                >
                                    Send message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <AiAcademyToast />
            <Footer />
        </div>
    );
};

export default MachineTranslationPage;
